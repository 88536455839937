import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
// import styles from "./LeaftySection.module.scss";

export default function LeaftySection() {
  return (
    <div className="bg-light-yellow">
      <Container className="section">
        <Row>
          <Col>
            <StaticImage
              src="../../../../images/Home/leafty-banner.png"
              alt="Logo de Leafty"
              placeholder="blurred"
              className="cover"
              objectFit="cover"
              loading="eager"
              layout="fullWidth"
            />
          </Col>
          <Col>
            <h2>Envie de voyager en harmonie la nature ?</h2>
            <p>
              Retrouvez Leafty, le planificateur de voyage éco-responsable.
              <br /> Une application de voyage à faible impact
              carbone, pour conserver et protéger le climat ainsi que la biodiversité. 🙌
            </p>
            <Button
              href="https://www.leafty.app/"
              className="btn btn-orange"
              size="lg"
            >
              Planifiez votre 1er voyage
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
