import * as React from "react";
import { graphql, Link, PageProps } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { Col, Row, Container, Button } from "react-bootstrap";

import Meta from "../components/Layout/head";
import Layout from "../components/Layout/layout";
import ParksCarousel from "../components/Park/ParksCarousel";
import Search from "../components/search/search";
import validateURL from "../components/security/validate-url";

import illustration from "../images/bg-illustration.svg";
import instagram from "../images/icons/icon_instagram.svg";
import twitter from "../images/icons/icon_twitter.svg";
import media from "../images/default-social-share.jpg";
import HikeUrbanAreaCarousel from "../components/HikeUrbanarea/UrbanareaCarousel";

import LeaftySection from "../components/Pages/Home/LeaftySection/LeaftySection";

export const Head = () => (
  <Meta
    title={`Application de randonnée gratuite`}
    description={`Découvrez votre prochaine destination de vacances en nature pour de futures randonnées et expéditions inoubliables.`}
    lang="fr"
    media={`https://www.rheaparks.com${media}`}
    pathname=""
  ></Meta>
);

export default function HomePage({ data }: PageProps<Queries.HomePageQuery>) {
  const parks = data.allContentfulPark;
  const hikeUrbanAreas = data.allContentfulHikeUrbanArea;

  return (
    <Layout>
      <Container fluid className="hero-banner">
        <Row>
          <Col>
            <div>
              <StaticImage
                src="../images/photos/bg-grand-paradis.jpg"
                alt="Randonnée dans le parc du Grand Paradis en Italie"
                placeholder="blurred"
                className="cover"
                objectFit="cover"
                loading="eager"
                layout="fullWidth"
              ></StaticImage>
              <div className="banner__bgColor bg-gradient-green">
                <div className="position-absolute top-50 start-50 translate-middle w-100">
                  <Container>
                    <Row>
                      <Col md="7" className="my-auto">
                        <h1>L'application de randonnée</h1>
                        <h2>
                          pour voyager au cœur des{" "}
                          <b>plus beaux parcs naturels</b> de France
                        </h2>
                        <Row className="mt-5 mb-3">
                          <Col xs="12" sm="10" md="8">
                            <Search
                              placeholder="Trouver ma prochaine destination…"
                              className="lg-input"
                            />
                          </Col>
                        </Row>
                        <Button
                          className="btn btn-orange lg-btn mb-2"
                          href="https://apps.apple.com/fr/app/rheaparks/id1546829212?platform=iphone"
                          size="lg"
                          target="_blank"
                          rel="noopener"
                        >
                          Télécharger sur l'AppStore
                        </Button>
                        <p>(Prochainement sur Android)</p>
                      </Col>
                      <Col md="5">
                        <StaticImage
                          alt="Écran des randonnées du parc"
                          src="../images/screenshots/1-find-hikes.png"
                          placeholder="blurred"
                          layout="constrained"
                          className="main-screenshot"
                          loading="eager"
                        />
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <div className="py-5">
        <Container>
          <h2>Nos meilleures destinations de randonnée</h2>
          <Link to="/randonnees/">
            Voir tous les parcs naturels où randonner ‣
          </Link>
          <ParksCarousel data={parks} />
        </Container>
      </div>

      <div className="pb-5">
        <Container>
          <h2>Top des meilleures villes pour randonner</h2>
          <Link to="/randonnees/">Voir toutes les villes où randonner ‣</Link>
          <HikeUrbanAreaCarousel data={hikeUrbanAreas} />
        </Container>
      </div>

      <div className="py-5 bg-light-yellow index-bullet-section">
        <Container className="green">
          <Row className="my-4">
            <Col xs="12" md="6">
              <Container>
                <Row>
                  <Col md="12">
                    <div className="bg-white rounded-md pt-5 pb-4 mb-4 px-5 position-relative top-30">
                      <div className="number-position">
                        <span>1</span>
                      </div>
                      <h3 className="">
                        Trouvez rapidement le plus beau parc naturel où partir
                        en vacances
                      </h3>
                      <p>
                        Rheaparks met en avant les plus beaux parcs naturels,
                        tout en offrant un aperçu de chacun de ces parcs en
                        photo. Une bonne façon pour déterminer quel parc vous
                        correspond le plus et fera l’objet de votre prochaine
                        destination de vacances !
                      </p>
                    </div>
                    <div className="bg-white rounded-md pt-5 pb-4 mt-4 px-5 position-relative top-60">
                      <div className="number-position">
                        <span>2</span>
                      </div>
                      <h3 className="">
                        Sélectionnez les meilleures randonnées du parc selon vos
                        envies
                      </h3>
                      <p>
                        Retrouvez toutes les plus belles randonnées des parcs
                        naturels accompagné d’un aperçu rapide de la balade.
                        Parcourez une sélection d’images, pour s’immerger et
                        connaître dès maintenant les expéditions que vous
                        aimeriez réaliser sur place.
                      </p>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Col>
            <Col
              xs={{ span: 10, offset: 1 }}
              md={{ span: 3, offset: 2 }}
              className="position-relative app-screen"
            >
              <div>
                <StaticImage
                  alt="Image de l'application de randonnée"
                  src="../images/screenshots/2-hike.png"
                  placeholder="blurred"
                  layout="constrained"
                  width={306}
                  height={612}
                />
              </div>
            </Col>
          </Row>

          <Row className="my-4">
            <Col
              xs={{ span: 10, order: "last", offset: 1 }}
              md={{ span: 3, offset: 1, order: "first" }}
              className="position-relative app-screen"
            >
              <StaticImage
                alt="Image de l'application de randonnée"
                src="../images/screenshots/5-alternatives.png"
                placeholder="blurred"
                layout="constrained"
                width={306}
                height={612}
              />
            </Col>
            <Col
              xs={{ span: 12, order: "first" }}
              md={{ span: 6, offset: 1, order: "last" }}
            >
              <Container>
                <Row>
                  <Col md="12">
                    <div className="bg-white rounded-md pt-5 pb-4 mb-4 px-5 position-relative top-30">
                      <div className="number-position">
                        <span>3</span>
                      </div>
                      <h3>Rendez-vous facilement au départ de la randonnée</h3>
                      <p>
                        Pour se garer à proximité des balades, l’application
                        Rheaparks vous guide pour chaque randonnée jusqu’à un
                        parking ou un emplacement de stationnement. Une fois sur
                        place, vous aurez directement accès au tracé de la
                        randonnée afin de démarrer l’expédition.{" "}
                      </p>
                    </div>
                    <div className="bg-white rounded-md pt-5 pb-4 mt-4 px-5 position-relative top-60">
                      <div className="number-position">
                        <span>4</span>
                      </div>
                      <h3>
                        Suivez les sentiers simplement, depuis l'application
                      </h3>
                      <p>
                        Laissez-vous guider tout au long de votre excursion, à
                        l’aide du tracé de la randonnée au cœur de la carte de
                        Rheaparks. Vous saurez ainsi exactement quel sentier
                        prendre à chaque virage, et pourrez poursuivre votre
                        balade en toute sérénité.
                      </p>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>

      <Container fluid className="bg-light-yellow section-banner">
        <Row className="gx-3">
          <Col className="bg-green" md="6" sm="12">
            <Container className="py-5 light-yellow">
              <Row>
                <Col sm="12" md={{ span: 8, offset: 2 }}>
                  <p className="size-xl font-weight-bold">
                    Respectez la nature…
                    <br />
                    Et appréciez-la !
                  </p>
                  <p className="line-height-md">
                    Que ce soit au cœur de parcs nationaux ou de parcs
                    régionaux, ces derniers espaces protégés offrent
                    d’incroyables paysages naturels. Et ce, en plus d’offrir une
                    chance d’observer des animaux en pleine liberté ainsi que de
                    rares espèces de flores. Lacs, cascades, forêts, plages… de
                    belles randonnées restent à découvrir.
                  </p>
                  <Button
                    className="btn btn-orange lg-btn"
                    href="https://apps.apple.com/fr/app/rheaparks/id1546829212?platform=iphone"
                    size="lg"
                    target="_blank"
                    rel="noopener"
                  >
                    Télécharger sur l'AppStore
                  </Button>
                </Col>
              </Row>
            </Container>
          </Col>
          <Col md="6" sm="12">
            <StaticImage
              src="../images/photos/parc-du-grand-paradis.jpg"
              alt="Lillaz au parc national du Grand Paradis"
              placeholder="blurred"
              className="cover"
            />
          </Col>
        </Row>
      </Container>

      <div className="bg-light-yellow">
        <Container className="green">
          <Row className="py-5 line-height-md">
            <Col xs="12" md={{ span: 5, offset: 1 }}>
              <Container>
                <Row>
                  <Col>
                    <StaticImage
                      alt="Forêt pour randonner"
                      src="../images/foret.png"
                      placeholder="blurred"
                      width={70}
                      height={70}
                    />
                  </Col>
                </Row>
              </Container>
              <h3>
                Une application pour découvrir les plus beaux parcs naturels
              </h3>
              <p>
                Que ce soit pour un week-end ou bien vos prochaines vacances,
                Rheaparks liste les plus beaux parcs naturels à découvrir au
                cœur de la France. De magnifiques destinations où le calme et la
                nature prennent le dessus. Où les animaux se laissent observer
                et de magnifiques points de vue se laissent admirer.
              </p>
            </Col>
            <Col xs="12" md={{ span: 5, offset: -1 }}>
              <Container>
                <Row>
                  <Col>
                    <StaticImage
                      alt="Montagne pour randonner"
                      src="../images/montagne.png"
                      placeholder="blurred"
                      width={70}
                      height={70}
                    />
                  </Col>
                </Row>
              </Container>
              <h3>Une application pour parcourir les meilleures randonnées</h3>
              <p>
                Afin de faciliter l’organisation de ses vacances, Rheaparks met
                en avant les randonnées les plus réputées et plus appréciées au
                cœur des parcs naturels. Une façon de choisir selon vos envies,
                les plus spectaculaires et réputés pour un moment inoubliable ou
                bien, les randonnées moins fréquentées pour un moment de charme
                et de tranquillité.
              </p>
            </Col>
          </Row>
        </Container>
      </div>

      <Container fluid className="section-banner">
        <Row>
          <Col xs={12} md={12}>
            <div className="position-relative">
              <img
                src={illustration}
                className="cover"
                alt="Illustration de paysages de randonnée - Rheaparks"
                loading="lazy"
              />
              <div className="banner__bgColor bg-dark-green"></div>
              <div className="position-absolute top-50 start-50 translate-middle w-100 text-center">
                <h2 className="text-white">
                  Téléchargez gratuitement l’application de randonnée Rheaparks
                </h2>
                <p className="text-white">
                  Et accédez à des centaines de randonnées au cœur de parcs
                  naturels français.
                </p>
                <Button
                  className="btn btn-orange"
                  href="https://apps.apple.com/fr/app/rheaparks/id1546829212?platform=iphone"
                  size="lg"
                  target="_blank"
                  rel="noopener"
                >
                  Télécharger sur l'AppStore
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <div className="green">
        <Container>
          <Row>
            <Col md={{ span: 10, offset: 1 }} className="my-5 text-center">
              <p className="orange size-xxl font-weight-bold mb-0">
                #Rheaparks
              </p>
              <p className="size-md">
                Vous êtes un <u>instagrameur</u> passionnés de voyage / nature
                et <u>randonnée</u> ?
              </p>
              <p className="size-sm">
                Partagez-nous vos photos en nous mentionnant via #Rheaparks, et
                nous les publierons peut-être sur Rheaparks en mettant en avant
                votre compte @instagram ! 😉
              </p>
              <Row className="mb-2 pt-5">
                <Col>
                  <div className="d-flex justify-content-end">
                    <b>Suivez nos aventures !</b>
                  </div>
                  <Row>
                    <Col sm={{ span: 6, offset: 6 }} xs="12">
                      <div className="d-flex flex-row-reverse">
                        <div className="p-2">
                          <div className="py-2">
                            <a
                              href={validateURL(
                                "https://www.instagram.com/rheaparks_app/"
                              )}
                            >
                              @rheaparks_app
                            </a>
                          </div>
                        </div>
                        <div className="p-2">
                          <a
                            href={validateURL(
                              "https://www.instagram.com/rheaparks_app/"
                            )}
                            className="social-icon"
                          >
                            <img
                              src={instagram}
                              alt="Icône Instagram de Rheaparks"
                              loading="lazy"
                            />
                          </a>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <div className="d-flex flex-row">
                    <b>Suivez le développement</b>
                  </div>
                  <Row>
                    <Col sm="6" xs="12">
                      <div className="d-flex flex-row">
                        <div className="p-2">
                          <a
                            href={validateURL(
                              "https://twitter.com/rheaparks_app"
                            )}
                            className="social-icon"
                          >
                            <img
                              src={twitter}
                              alt="Icône Twitter de Rheaparks"
                              loading="lazy"
                            />
                          </a>
                        </div>
                        <div className="p-2">
                          <div className="py-2">
                            <a
                              href={validateURL(
                                "https://twitter.com/rheaparks_app"
                              )}
                            >
                              @rheaparks_app
                            </a>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <p>
                Merci de participer dans ce projet, réalisé par amour pour la
                nature.
              </p>
            </Col>
          </Row>
        </Container>
      </div>

      <LeaftySection />

      <div className="green">
        <Container>
          <Row>
            <Col className="my-5">
              <div className="my-3 text-center">
                <h2>Vous représentez un parc naturel ?</h2>
                <div className="size-sm">
                  <p>
                    Contactez-nous afin que nous discutions de l’ajout de votre
                    parc au cœur de l’application !
                  </p>
                  <p>contact(@)rheaparks.com</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  );
}

export const HomePageQuery = graphql`
  query HomePage {
    allContentfulPark(filter: { node_locale: { eq: "fr" } }) {
      ...AllParksList
    }
    allContentfulHikeUrbanArea(filter: { node_locale: { eq: "fr" } }) {
      ...AllHikesUrbanAreaList
    }
  }
`;
