export default function countryDestination(country) {

    const Country = {
        France: "fr",
        Italy: "it",
        Switzerland: "ch",
        Albania: "al",
        Austria: "at",
        Spain: "es",
        Germany: "de",
        Slovenia: "si",
        // Americas
        UnitedStates: "us",
        Canada: "ca",
    }

    // enum Country {
    //     France = "fr",
    //     Italy = "it",
    //     Switzerland = "ch",
    //     Albania = "al",
    //     Austria = "at",
    //     Spain = "es",
    //     Germany = "de",
    //     Slovenia = "si",
    //     // Americas
    //     UnitedStates = "us",
    //     Canada = "ca",
    // }

    let accommodationType = "";

    if(country !== null) {
        switch (country) {
            case Country.France: 
                accommodationType = "france";
            break;
            case Country.Italy: 
                accommodationType = "italie"; 
            break;           
            case Country.Switzerland: 
                accommodationType = "suisse";
            break;
            case Country.Albania: 
                accommodationType = "albanie";
            break;
            case Country.Austria: 
                accommodationType = "autriche";
            break;
            case Country.Spain: 
                accommodationType = "espagne";
            break;
            case Country.Germany: 
                accommodationType = "allemagne";
            break;
            case Country.Slovenia: 
                accommodationType = "slovenie";
            break;
            case Country.UnitedStates: 
                accommodationType = "etats-unis";
            break;
            case Country.Canada: 
                accommodationType = "canada";
            break;
            default: 
                accommodationType = "inconnu";
            break;
        }
    }

    return accommodationType;
}