import React from "react";
import UrbanAreaCard from "./UrbanAreaCard";
import Carousel from "../UI/Carousel/Carousel";

// https://react-slick.neostack.com/docs/example/

interface Props {
  data: Queries.AllHikesUrbanAreaListFragment
}
export default class HikeUrbanAreaCarousel extends React.Component<Props> {

  constructor(props: Props) {
    super(props)
  }

  render() {
    const allUrbanArea = this.props.data;

    if(allUrbanArea) {
      const listOfCards = allUrbanArea.nodes.flatMap((urbanArea) => urbanArea ? [urbanArea] : []) // Filter null elements
      .map((urbanArea) => (
        <UrbanAreaCard {...urbanArea} key={urbanArea?.id} />
      ))
      
      const carouselSettings = {
        responsive: {
          breakpoint1500: {
            slidesToShow: 5.2,
          },
          breakpoint1024: {
              slidesToShow: 3.5,
          },
          breakpoint768: {
              slidesToShow: 2.8,
          },
          breakpoint480: {
              slidesToShow: 2.2,
          }
        },
        className: "center",
        centerPadding: "60px",
        slidesToShow: 4.2,
        slidesToScroll: 3,
        infinite: false,
      }
  
      const carouselProps = { listOfCards, carouselSettings }
  
      return (
          <Carousel {...carouselProps} />
      )
    }
    return null;
  
  }
}